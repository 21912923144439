import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/ApiRef.js";
import CodeSnippet from 'components/CodeSnippet';
import * as CODE from '../../constants/reporting';
import { APIWrapper, LeftColumn, RightColumn } from 'components/ApiRef';
import Notification from 'components/Notification';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Reporting API `}<inlineCode parentName="h1">{`beta`}</inlineCode></h1>
    <Notification type="INFO" mdxType="Notification">
Be the first to explore your data in our new reporting API, now in beta. You can start testing the Reporting API using the REST API key that is provided in your Dashboard <a href="https://dashboard.messagebird.com/en/developers/access" target="_blank" rel="noopener noreferrer">here</a>. If you would have feedback or missing functionality, email our product manager <a href="mailto:reporting@messagebird.com" target="_blank" rel="noopener noreferrer">here</a>. We are working on adding Inbox Reporting.
    </Notification>
    <p>{`MessageBird’s Reporting API enables you to programmatically retrieve reports on SMS, Voice, and Conversations performed on our platform. You can use various filters and group-by parameters for your requests and generate personalized reports for any period in which you have used our APIs and aggregate them with monthly, daily, or even hourly granularity. `}</p>
    <p>{`Statistics data is available in near real-time. The Reporting API can help you monitor and analyze your use of MessageBird APIs so you can optimize your usage with data insights.`}</p>
    <p>{`The Reporting API uses `}<inlineCode parentName="p">{`HTTP verbs`}</inlineCode>{` and a `}<inlineCode parentName="p">{`RESTful`}</inlineCode>{` endpoint structure with an access key that is used as the API Authorization framework. Request and response payloads are formatted as JSON—although we provide a GET alternative for requests—using UTF-8 encoding and URL encoded values.`}</p>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <hr></hr>
        <h2>{`API Endpoint`}</h2>
        <p>{`All URLs referenced in MessageBird's Reporting Messaging API documentation have the base URL in the code snippet on the right side:`}</p>
        <Notification type="INFO" mdxType="Notification">
The request parameters values have to be URL-encoded. Please check the documentation of your programming language or HTTP client, a method or function for URL-encoding should be provided. For example, the date and time `2018-04-01T15:04:05+07:00` would be `2018-04-01T15%3A04%3A05%2B07%3A00` after URL-encoding.
        </Notification>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>API Endpoint</h4>
  <CodeSnippet code={CODE.REPORTING_URL} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <hr></hr>
    <h2>{`Rate limiting`}</h2>
    <p>{`MessageBird has made near real-time insights available to our customers. Still, analytical queries with aggregations and filters on a vast amount of data could take a long time and resources. To ensure reliable service for all our customers, we impose rate limiting on this API. More precisely, per Access Key. We will handle at most five requests per second and five simultaneous requests. Excess requests receive an HTTP status 429 (Too Many Requests).`}</p>
    <hr></hr>
    <h2>{`Pagination`}</h2>
    <p>{`Statistics over an extended period could have a large number of items. To make requests more responsive, we provide consistent pagination.`}</p>
    <p>{`We have updated the way of pagination to make it more consistent with our other APIs. Now a part of items can be obtained by specifying `}<inlineCode parentName="p">{`offset`}</inlineCode>{` and `}<inlineCode parentName="p">{`limit`}</inlineCode>{`. `}<inlineCode parentName="p">{`offset`}</inlineCode>{` means the number of elements to skip and `}<inlineCode parentName="p">{`limit`}</inlineCode>{` represents the number of items to return. For example, to get the first 100 items, `}<inlineCode parentName="p">{`offset`}</inlineCode>{` and `}<inlineCode parentName="p">{`limit`}</inlineCode>{` should be set to 0 and 100, respectively. The response will contain 100 elements (possibly less if there are less than 100 items in total), a field `}<inlineCode parentName="p">{`totalCount`}</inlineCode>{` representing the number of items in total and a reference token `}<inlineCode parentName="p">{`ref`}</inlineCode>{`. `}<inlineCode parentName="p">{`ref`}</inlineCode>{` ensures consistent pagination, meaning that the dataset will not change with updated information as long as the reference token remains the same. To obtain any other part of the result, you need to `}<strong parentName="p">{`change `}<inlineCode parentName="strong">{`offset`}</inlineCode>{` and `}<inlineCode parentName="strong">{`limit`}</inlineCode>{` and set the reference token accordingly in your new request`}</strong>{`. Note the token will expire in one day.`}</p>
    <p><strong parentName="p">{`Deprecated`}</strong>{`: For SMS reporting only, by default, the first page with 500 items is returned, if the total number of items is larger than 500. Otherwise, a single page containing all items is returned. A user can also specify any integer number between 1 and 2500 (both inclusive) as the page size. Along with the first page, the response includes a link to get the next page (if applicable). The link looks almost identical to the original request, except for pageNumber, pageSize, and a reference token. This token ensures that a customer always has consistent pagination, meaning that the dataset will not change with updated information as long as the reference token remains the same. If there are any following pages, a user can iterate through them by following those links. A user may jump over several pages by setting `}<inlineCode parentName="p">{`pageSize`}</inlineCode>{` and `}<inlineCode parentName="p">{`pageNumber`}</inlineCode>{`. Note that a reference token is required for any page after the first.`}</p>
    <p>{`Pagination is only used with JSON responses. If you request CSV output, `}<inlineCode parentName="p">{`offset`}</inlineCode>{` and `}<inlineCode parentName="p">{`limit`}</inlineCode>{` or `}<inlineCode parentName="p">{`pageSize`}</inlineCode>{` and `}<inlineCode parentName="p">{`pageNumber`}</inlineCode>{` are ignored and the complete available dataset is returned. Moreover, you cannot use `}<inlineCode parentName="p">{`offset, limit`}</inlineCode>{` and `}<inlineCode parentName="p">{`pageSize, pageNumber`}</inlineCode>{` at the same time.`}</p>
    <hr></hr>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`SMS`}</h2>
        <h4>{`Required parameters`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Attribute`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`periodStart`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`datetime`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The date and time in UTC, RFC3339 format (Y-m-d\\TH:i:sP) for the start of the reporting period.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`periodEnd`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`datetime`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The date and time in UTC, RFC3339 format (Y-m-d\\TH:i:sP) for the end of the reporting period. This must be after periodStart.`}</td>
            </tr>
          </tbody>
        </table>
        <h4>{`Optional parameters`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Attribute`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`periodGroup`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The time granularity to group the statistics. Valid values are `}<inlineCode parentName="td">{`none, hour, day, week, month, year`}</inlineCode>{`. `}<strong parentName="td">{`Default: `}<inlineCode parentName="strong">{`day`}</inlineCode>{`.`}</strong></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`timezone`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The timezone to group and display the results in the time-dimension, such as “Europe/Amsterdam” or “US/Central”. All location names in the IANA Time Zone Database are valid. `}<strong parentName="td">{`Default: `}<inlineCode parentName="strong">{`UTC`}</inlineCode>{`.`}</strong></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`groupBy`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string list`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Zero or more values from `}<inlineCode parentName="td">{`country, networkCode, originator, account`}</inlineCode>{`. More than one value can be specified by repeating the query parameter. For example, `}<inlineCode parentName="td">{`groupBy=account&groupBy=country`}</inlineCode>{` groups items by account AND country. Note you cannot group by `}<inlineCode parentName="td">{`status`}</inlineCode>{` any more, for it is integrated into every insight. Each insight includes counts of messages that are in different statues, including `}<inlineCode parentName="td">{`delivered`}</inlineCode>{`, `}<inlineCode parentName="td">{`processing`}</inlineCode>{`, `}<inlineCode parentName="td">{`failed`}</inlineCode>{`, `}<inlineCode parentName="td">{`delivery_impossible`}</inlineCode>{` (for example an invalid recipient). `}<strong parentName="td">{`Default: empty.`}</strong></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`filterBy[]`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`You can specify multiple filters as explained below. Specifying the same type of filter multiple times with different values is considered a union query, which means that the reports contains all messages that match one of the conditions. The same is true if multiple country-related filters are defined, for example `}<inlineCode parentName="td">{`filterBy[mcc]=204&filterBy[countryCode]=US`}</inlineCode>{` returns all messages sent to the Netherlands or the United States (see explanations below). Specifying multiple different filters results in an intersection query, for example `}<inlineCode parentName="td">{`filterBy[countryCode]=UK&filterBy[originator]=messagebird`}</inlineCode>{` only returns messages sent to the United Kingdom from MessageBird.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`filterBy[account]`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Filters by sub account name. More than one account name can be provided by repeating the query parameter, for example `}<inlineCode parentName="td">{`filterBy[account]=account1&filterBy[account]=account2`}</inlineCode></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`filterBy[mcc]`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`integer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A three digit Mobile Country Code, as defined in ITU E.212, for example 204 for the Netherlands. More than one MCC can be provided by repeating the query parameter, such as `}<inlineCode parentName="td">{`filterBy[mcc]=204&filterBy[mcc]=404&filterBy[mcc]=405`}</inlineCode>{`. `}<strong parentName="td">{`Default: empty.`}</strong></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`filterBy[countryCode]`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`An ISO 639 country code, for example DE for Germany. It will be translated to `}<inlineCode parentName="td">{`filterBy[mcc]`}</inlineCode>{`. For example, `}<inlineCode parentName="td">{`filterBy[countryCode]=NL`}</inlineCode>{` will be translated to `}<inlineCode parentName="td">{`filterBy[mcc]=204`}</inlineCode>{`. More than one code can be provided by repeating the query parameter, for example `}<inlineCode parentName="td">{`filterBy[countryCode]=NL&filterBy[countryCode]=IN`}</inlineCode>{`. `}<strong parentName="td">{`Default: empty.`}</strong></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`filterBy[countryPrefix]`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`integer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A country telephone prefix, for example 91 for India. It will be translated to `}<inlineCode parentName="td">{`filterBy[mcc]`}</inlineCode>{`. For example, `}<inlineCode parentName="td">{`filterBy[countryCode]=31`}</inlineCode>{` will be translated to `}<inlineCode parentName="td">{`filterBy[mcc]=204`}</inlineCode>{`. More than one prefix can be provided by repeating the query parameter, for example `}<inlineCode parentName="td">{`filterBy[countryPrefix]=31&filterBy[countryPrefix]=91`}</inlineCode>{`.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`filterBy[networkCode]`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`integer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A 5-6 digit Mobile Network Code, as defined in ITU-T E.212 (MCC/MNC tuple), for example 20408 for KPN Mobile Netherlands. More than one code can be provided by repeating the query parameter.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`filterBy[originator]`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`An originator that has been used for sending messages, which can either be a phone number or an alphanumeric sender. Note messages sent before March 9, 2018 cannot be filtered based on this field.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`filterBy[status]`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Filters by message status, valid values are `}<inlineCode parentName="td">{`delivered, processing, failed, delivery_impossible`}</inlineCode>{`. More than one status can be provided by repeating the query parameter, for example `}<inlineCode parentName="td">{`filterBy[status]=processing&filterBy[status]=delivered`}</inlineCode></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`sort`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Specify sort criteria with the following syntax: `}<inlineCode parentName="td">{`key1:direction1[,key2:direction2]`}</inlineCode>{`. If a direction is omitted, asc (ascending) is assumed. Valid keys are `}<inlineCode parentName="td">{`submittedCount, deliveredCount, processingCount, failedCount, deliveryImpossibleCount`}</inlineCode>{`. Valid directions are `}<inlineCode parentName="td">{`asc, desc`}</inlineCode>{`. The appearance order of more than one key will be preserved when sorting a result. Further, the sorted result will be sorted by time in ascending order. For example, `}<inlineCode parentName="td">{`sort=deliveredCount:desc`}</inlineCode>{` will sort results by `}<inlineCode parentName="td">{`deliveredCount`}</inlineCode>{` in descending order. And the items having the same deliveredCount will be sorted in time ascending order.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`pageNumber`}</inlineCode>{` `}{`[deprecated]`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`integer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The page to be accessed. `}<strong parentName="td">{`Default: 1, the first page.`}</strong></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`pageSize`}</inlineCode>{` `}{`[deprecated]`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`integer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Valid values are any integer number between 1 and 2500, both inclusive. A number larger than 2500 will be clipped to 2500. `}<strong parentName="td">{`Default: 500.`}</strong></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`offset`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`integer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The number of elements to skip. `}<strong parentName="td">{`Default: 0, skipping zero element.`}</strong></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`limit`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`integer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The number of elements to return. Valid values are any integer number between 1 and 2500, both inclusive. A number larger than 2500 will be clipped to 2500. `}<strong parentName="td">{`Default: 500.`}</strong></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`ref`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A string to ensure consistency of pagination. When accessing the first part of result, it will be included in the response. To access further parts it needs to be added as a request query parameter.`}</td>
            </tr>
          </tbody>
        </table>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Available HTTP Methods</h4>
  <CodeSnippet code={CODE.SMS_METHODS} mdxType="CodeSnippet" />
  <h4>Example Request</h4>
  <CodeSnippet code={CODE.SMS_EXAMPLE} mdxType="CodeSnippet" />  
  <h4>Example Response</h4>
  <CodeSnippet code={CODE.SMS_RESPONSE} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <p>{`The desired output format can be specified with the Accept header. Valid values are `}<inlineCode parentName="p">{`application/json`}</inlineCode>{` (default) and `}<inlineCode parentName="p">{`text/csv`}</inlineCode>{`.`}</p>
    <h3>{`SMS Report object`}</h3>
    <p>{`This object represents a statistic.`}</p>
    <h4>{`Attributes`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`items`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`array`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Messages are split by provided grouping fields. Each item corresponds to statistics of a group.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`items[].timestamp`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`datetime`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The timestamp in RFC3339 format (Y-m-d\\TH:i:sP). The first second of hour / day / week / month / year will be used to represent the period group. For example, `}<inlineCode parentName="td">{`2018-04-09T00:00:00Z`}</inlineCode>{` represents the 15th week in 2018 or the first hour of the day April 9, 2018 or the day itself.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`items[].message`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`object`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contains details about the messages based on the groupBy parameters. If no groupBy parameter was specified this object will be empty.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`items[].submittedCount`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The number of messages submitted to MessageBird in the specified time period.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`items[].deliveredCount`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The number of messages delivered in the specified time period.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`items[].processingCount`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The number of messages being processed at MessageBird.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`items[].failedCount`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The number of messages failed to deliver.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`items[].deliveryImpossibleCount`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The number of messages that were impossible to deliver, for example an invalid recipient.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ref`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A string to ensure consistency of pagination. A ref expires one day after creation and its lifetime cannot be extended.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`prev`}</inlineCode>{` `}{`[deprecated]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A link to get the previous page. Omitted for the first page. Note you still need to set authorization.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`next`}</inlineCode>{` `}{`[deprecated]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A link to get the next page. Omitted for the last page. Note you still need to set authorization.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`totalCount`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The total number of items that can be retrieved for this request through pagination.`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`The message object`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`mcc`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Mobile Country Code, e.g. 204.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`countryName`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Full English name of the destination country.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`networkCode`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`MCC + MNC (Mobile Network Code), e.g. 20408.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`originator`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The originator, which can either be a phone number or an alphanumeric sender. It is possible that the originator is unavailable, for example because the owner of the phone number has made a request to remove it from logs. In this case, a secure hash string prefixed with “UNKNOWN” will be returned instead, which is consistent among different requests. Note originators of all messages sent before March 9, 2018 will be “UNKNOWN”.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`account`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Account name. Exists only when group by account.`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`CSV format response fields`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`timestamp`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`datetime`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The date and time in RFC3339 format (Y-m-d\\TH:i:sP). The first second of hour / day / week / month / year will be used to represent the period group. For example, `}<inlineCode parentName="td">{`2018-04-09T00:00:00Z`}</inlineCode>{` represents the 15th week in 2018 or the first hour of the day April 9, 2018 or the day itself. Exists only when periodGroup is not none.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`mcc`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Mobile country code. Exists only when group by country.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`countryName`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Full English name of the destination country. Exists only when group by country. For some virtual numbers, we don’t have that data and the `}<inlineCode parentName="td">{`countryName`}</inlineCode>{` will show as Unknown.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`networkCode`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`MCC + MNC (mobile network code). Exists only when group by networkCode.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`originator`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The originator. Exists only when group by originator.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`account`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Account name. Exists only when group by account.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`submittedCount`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The number of messages submitted to MessageBird in the specified time period.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`deliveredCount`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The number of messages delivered among the submitted.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`processingCount`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The number of messages being processed at MessageBird.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`failedCount`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The number of messages failed to deliver.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`deliveryImpossibleCount`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The number of messages that were impossible to deliver, for example an invalid recipient.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`SMS Example requests`}</h3>
    <p>{`You can retrieve a variety of insights from the Reporting API through different combinations of filterBy and groupBy parameters. We have collected a variety of requests below to serve as an inspiration for you to build your own queries.`}</p>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h4>{`Successful messages grouped by country`}</h4>
        <p>{`Imagine you’re running a global SMS-based marketing campaign and want to know how many people you have reached in the last month in each country. You can group by country. And, because you just need total numbers, a monthly granularity is enough.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Successful messages grouped by country</h4>
  <CodeSnippet code={CODE.SMS_EXAMPLE1} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h4>{`Messages from an originator`}</h4>
        <p>{`It’s important to know how many text messages are actually received and how many get stuck in the network to understand whether your SMS-based campaigns are successful. Also, let’s say you’re running multiple campaigns on the same account but each uses a different originator. You’re only interested in two of them, so you can use them both as filter criteria and group by originator as well.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Messages from an originator</h4>
  <CodeSnippet code={CODE.SMS_EXAMPLE2} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h4>{`Messages for a country and originator grouped by network`}</h4>
        <p>{`Imagine you’re getting customer complaints about not receiving messages. Anecdotally the customers with these complaints seem to have the same provider, so you want to dive into this and investigate messages by the network code of the receiver. To keep the data manageable you add a filter for your originator and one country at which you want to look. Also you choose daily granularity, so you can also analyze whether those problems occur differently over time.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Messages for a country and originator grouped by network</h4>
  <CodeSnippet code={CODE.SMS_EXAMPLE3} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h4>{`Iterate over all items`}</h4>
        <p>{`Requesting the first part gives us the first 100 items and `}<inlineCode parentName="p">{`ref: b5ba8e88b10011e896f8529269fb1459`}</inlineCode>{`. In order to get the second part, run the second request.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Iterate over all items</h4>
  <CodeSnippet code={CODE.SMS_EXAMPLE4} mdxType="CodeSnippet" />
  <CodeSnippet code={CODE.SMS_EXAMPLE5} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <hr></hr>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`Call`}</h2>
        <h4>{`Required parameters`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Attribute`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`periodStart`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`datetime`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The date and time in UTC, RFC3339 format (Y-m-d\\TH:i:sP) for the start of the reporting period.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`periodEnd`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`datetime`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The date and time in UTC, RFC3339 format (Y-m-d\\TH:i:sP) for the end of the reporting period. This must be after periodStart.`}</td>
            </tr>
          </tbody>
        </table>
        <h4>{`Optional parameters`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Attribute`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`periodGroup`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The time granularity to group the statistics. Valid values are `}<inlineCode parentName="td">{`none, hour, day, week, month, year`}</inlineCode>{`. `}<strong parentName="td">{`Default: `}<inlineCode parentName="strong">{`day`}</inlineCode>{`.`}</strong></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`timezone`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The timezone to group and display the results in the time-dimension, such as “Europe/Amsterdam” or “US/Central”. All location names in the Time Zone Database are valid. `}<strong parentName="td">{`Default: `}<inlineCode parentName="strong">{`UTC`}</inlineCode>{`.`}</strong></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`groupBy`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string list`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Zero or more values from `}<inlineCode parentName="td">{`status, source, sourceCountry, sourceOperatorPrefix, sourceType, destination, destinationCountry, destinationOperatorPrefix, destinationType`}</inlineCode>{`. More than one value can be specified by repeating the query parameter groupBy. For example, `}<inlineCode parentName="td">{`groupBy=status&groupBy=sourceCountry`}</inlineCode>{` groups items by status AND source country. `}<strong parentName="td">{`Default: empty.`}</strong></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`filterBy[]`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`You can specify multiple filters as explained below. Specifying the same type of filter multiple times with different values is considered a union query, which means that the reports contains all calls that match one of the conditions. Specifying multiple different filters results in an intersection query, for example `}<inlineCode parentName="td">{`filterBy[sourceCountryPrefix]=44&filterBy[status]=ended`}</inlineCode>{` only returns calls sent to the United Kingdom that were successful. `}<strong parentName="td">{`Default: empty.`}</strong></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`filterBy[source]`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A source that has been used for creating a call, which can either be a phone number or an alphanumeric caller. `}<strong parentName="td">{`Default: empty.`}</strong></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`filterBy[sourceCountryCode]`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`An ISO 639 country code, for example DE for Germany. More than one code can be provided by repeating the query parameter, such as `}<inlineCode parentName="td">{`filterBy[sourceCountryCode]=NL&filterBy[sourceCountryCode]=UK`}</inlineCode>{`. `}<strong parentName="td">{`Default: empty.`}</strong></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`filterBy[sourceCountryPrefix]`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`integer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A country phone number prefix, for example 1 for USA and Canada. More than one prefix can be provided by repeating the query parameter. This will be translated to `}<inlineCode parentName="td">{`filterBy[sourceCountryCode]`}</inlineCode>{`. For example `}<inlineCode parentName="td">{`filterBy[sourceCountryPrefix]=1`}</inlineCode>{` will be translated to `}<inlineCode parentName="td">{`filterBy[sourceCountryCode]=CA&filterBy[sourceCountryCode]=US`}</inlineCode>{`. `}<strong parentName="td">{`Default: empty.`}</strong></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`filterBy[sourceOperatorPrefix]`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`integer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`An operator prefix, for example 86135 for a number belongs to China Mobile. More than one prefix can be provided by repeating the query parameter, such as `}<inlineCode parentName="td">{`filterBy[sourceOperatorPrefix]=86135&filterBy[sourceOperatorPrefix]=86139`}</inlineCode>{`. `}<strong parentName="td">{`Default: empty.`}</strong></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`filterBy[sourceType]`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Type of source number. Valid values are `}<inlineCode parentName="td">{`landline, mobile, paging, mobile_paging, mobile_landline, satellite, supplementary_services`}</inlineCode>{`, case insensitive. For example `}<inlineCode parentName="td">{`filterBy[sourceType]=landline`}</inlineCode>{`. `}<strong parentName="td">{`Default: empty.`}</strong></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`filterBy[destination]`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A destination that has been used for receiving a call, for example a phone number. `}<strong parentName="td">{`Default: empty.`}</strong></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`filterBy[destinationCountryCode]`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`An ISO 639 country code, for example DE for Germany. More than one code can be provided by repeating the query parameter. `}<strong parentName="td">{`Default: empty.`}</strong></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`filterBy[destinationCountryPrefix]`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`integer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A country phone number prefix, for example 91 for India. More than one prefix can be provided by repeating the query parameter. This will be translated to `}<inlineCode parentName="td">{`filterBy[destinationCountryCode]`}</inlineCode>{`. `}<strong parentName="td">{`Default: empty.`}</strong></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`filterBy[destinationOperatorPrefix]`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`integer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`An operator prefix, for example 86135 for a number belongs to China Mobile. More than one prefix can be provided by repeating the query parameter. `}<strong parentName="td">{`Default: empty.`}</strong></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`filterBy[destinationType]`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Type of destination number. Valid values are `}<inlineCode parentName="td">{`landline, mobile, paging, mobile_paging, mobile_landline, satellite, supplementary_services`}</inlineCode>{`, case insensitive. For example `}<inlineCode parentName="td">{`filterBy[destinationType]=landline`}</inlineCode>{`. `}<strong parentName="td">{`Default: empty.`}</strong></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`filterBy[status]`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A call status. See the Call Status table for possible values. More than one status can be provided by repeating the query parameter. `}<strong parentName="td">{`Default: empty.`}</strong></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`sort`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Specify sort criteria with the following syntax: `}<inlineCode parentName="td">{`key1:direction1[,key2:direction2]`}</inlineCode>{`. If a direction is omitted, asc (ascending) is assumed. Valid keys are `}<inlineCode parentName="td">{`count, successCount, failureCount, totalDuration, averageDuration, successRate, failureRate`}</inlineCode>{`. Valid directions are `}<inlineCode parentName="td">{`asc, desc`}</inlineCode>{`. The appearance order of more than one key will be preserved when sorting a result. Further, the sorted result will be sorted by time in ascending order. For example, `}<inlineCode parentName="td">{`sort=count:desc`}</inlineCode>{` will sort results by `}<inlineCode parentName="td">{`count`}</inlineCode>{` in descending order. And the items having the same sorting key values will be sorted in time ascending order if `}<inlineCode parentName="td">{`periodGroup`}</inlineCode>{` is not none. `}<strong parentName="td">{`Default: empty.`}</strong></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`offset`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`integer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The number of elements to skip. `}<strong parentName="td">{`Default: 0, skipping zero element.`}</strong></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`limit`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`integer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The number of elements to return. Valid values are any integer number between 1 and 2500, both inclusive. A number larger than 2500 will be clipped to 2500. `}<strong parentName="td">{`Default: 500.`}</strong></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`ref`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A string to ensure consistency of pagination. When accessing the first part of result, it will be included in the response. To access further parts it needs to be added as a request query parameter.`}</td>
            </tr>
          </tbody>
        </table>
        <p>{`The desired output format can be specified with the Accept header. Valid values are `}<inlineCode parentName="p">{`application/json`}</inlineCode>{` (default) and `}<inlineCode parentName="p">{`text/csv`}</inlineCode>{`.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Available HTTP Methods</h4>
  <CodeSnippet code={CODE.CALL_METHODS} mdxType="CodeSnippet" />  
  <h4>Example Request</h4>
  <CodeSnippet code={CODE.CALL_EXAMPLE} mdxType="CodeSnippet" />  
  <h4>Example Response</h4>
  <CodeSnippet code={CODE.CALL_RESPONSE} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <h4>{`The Call Status table`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Status`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`starting`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A call is being established.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ongoing`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A call is happening now.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ended`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A call has been finished successfully.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`failed`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An error has occurred when establishing a call.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`busy`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A call could not be established as the endpoint was busy.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`no_answer`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A call was not picked up.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`queued`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A call is awaiting to be initiated.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Call Report object`}</h3>
    <p>{`This object represents a statistic.`}</p>
    <h4>{`Attributes`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`items`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`array`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Calls are split by provided grouping fields. Each item corresponds to statistics of a group.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`items[].timestamp`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`datetime`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The timestamp in RFC3339 format (Y-m-d\\TH:i:sP). The first second of hour / day / week / month / year will be used to represent the period group. For example, `}<inlineCode parentName="td">{`2018-08-06T00:00:00Z`}</inlineCode>{` represents the 32th week in 2018 or the first hour of the day August 6, 2018 or the day itself.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`items[].call`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`object`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contains details about the calls based on the groupBy parameters. If no groupBy parameter was specified this object will be empty.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`items[].totalDuration`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The total duration (seconds) of calls in a group.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`items[].averageDuration`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`float`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The average duration (seconds) of calls in a group.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`items[].count`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The total number of calls in a group.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`items[].successCount`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The number of successful calls in a group.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`items[].failureCount`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The number of failed calls in a group.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`items[].successRate`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`float`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The average rate of successful calls in a group.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`items[].failureRate`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`float`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The average rate of failed calls in a group.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ref`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A string to ensure consistency of pagination. A ref expires one day after creation and its lifetime cannot be extended.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`totalCount`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The total number of items that can be retrieved for this request through pagination.`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`The call object`}</h4>
    <p>{`A call describes a voice call made to a number.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`source`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Endpoint object`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Source of a call.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`destination`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Endpoint object`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Destination of a call.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`status`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The status of calls in a group. See the Call Status table.`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`The Endpoint object`}</h4>
    <p>{`An endpoint is an object stores information about a number.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`number`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Number of the endpoint, such as 31648888888 and `}<inlineCode parentName="td">{`MessageBird`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`countryCode`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Country code of the endpoint. Exists only when group by country and number is a valid phone number.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`countryPrefix`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Country prefix of the endpoint. Exists only when group by country and number is a valid phone number..`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`operatorPrefix`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Operator prefix of the endpoint. Exists only when group by operator prefix and number is a valid phone number..`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`type`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Type of the endpoint. Exists only when group by type.`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`CSV format response fields`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`timestamp`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`datetime`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The date and time in RFC3339 format (Y-m-d\\TH:i:sP). The first second of hour / day / week / month / year will be used to represent the period group. For example, `}<inlineCode parentName="td">{`2018-04-09T00:00:00Z`}</inlineCode>{` represents the 15th week in 2018 or the first hour of the day April 9, 2018 or the day itself. Exists only when periodGroup is not none.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`source`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Source number of a call. Exists only when group by source.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`sourceCountryCode`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Country code of the source of a call. Exists only when group by source country.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`sourceCountryPrefix`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Country prefix of the source of a call. Exists only when group by source country.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`sourceOperatorPrefix`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Operator prefix of the source of a call.  Exists only when group by operator prefix.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`sourceType`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Type of the source of a call. Exists only when group by source type.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`destination`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Destination number of a call. Exists only when group by destination. Exists only when group by destination.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`destinationCountryCode`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Country code of the destination of a call. Exists only when group by destination country.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`destinationCountryPrefix`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Country prefix of the destination of a call. Exists only when group by destination country.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`destinationOperatorPrefix`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Operator prefix of the destination of a call. Exists only when group by destination operator prefix.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`destinationType`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Type of the destination of a call. Exists only when group by destination type.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`status`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The status of calls in a group. See the Call Status table. Exists only when group by status.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`totalDuration`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The total duration (seconds) of calls in a group.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`averageDuration`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`float`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The average duration (seconds) of calls in a group.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`count`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The total number of calls in a group.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`successCount`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The number of successful calls in a group.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`failureCount`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The number of failed calls in a group.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`successRate`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`float`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The average rate of successful calls in a group.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`failureRate`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`float`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The average rate of failed calls in a group.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Call Example requests`}</h3>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h4>{`Monthly calls grouped by country`}</h4>
        <p>{`Group all calls made in August, 2018 UTC+8 by source country to see calls originated from every country in August.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Monthly calls grouped by country</h4>
  <CodeSnippet code={CODE.CALL_EXAMPLE1} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h4>{`Daily calls grouped by country`}</h4>
        <p>{`Group all calls made in August, 2018 UTC+8 by source country and day to see daily calls originated from every country.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Daily calls grouped by country</h4>
  <CodeSnippet code={CODE.CALL_EXAMPLE2} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h4>{`Daily calls originated from the Netherlands`}</h4>
        <p>{`Group all calls made in August, 2018 UTC+8 from the Netherlands by day to see daily calls originated from the Netherlands.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Daily calls originated from the Netherlands</h4>
  <CodeSnippet code={CODE.CALL_EXAMPLE3} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h4>{`Daily calls originated from the Netherlands and Germany`}</h4>
        <p>{`Group all calls made in August, 2018 UTC+8 from the Netherlands and Germany by day to see daily calls originated from the two countries.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Daily calls originated from the Netherlands and Germany</h4>
  <CodeSnippet code={CODE.CALL_EXAMPLE4} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h4>{`Daily failed calls originated from the Netherlands`}</h4>
        <p>{`Group all calls made in August, 2018 UTC+8 from the Netherlands by day to see daily calls originated from the Netherlands.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Daily failed calls originated from the Netherlands</h4>
  <CodeSnippet code={CODE.CALL_EXAMPLE5} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h4>{`Daily distribution of caller types originated from the Netherlands`}</h4>
        <p>{`Group all calls made in August, 2018 UTC+8 from the Netherlands by day and sourceType to see daily distribution of caller types originated from the Netherlands.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Daily distribution of caller types originated from the Netherlands</h4>
  <CodeSnippet code={CODE.CALL_EXAMPLE6} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h4>{`Iterate over all items`}</h4>
        <p>{`Requesting the first part gives us the first 100 items and `}<inlineCode parentName="p">{`ref: 03aa7194b10111e88981529269fb1459`}</inlineCode>{`. In order to get the second part, run the second request.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Daily distribution of caller types originated from the Netherlands</h4>
  <CodeSnippet code={CODE.CALL_EXAMPLE7} mdxType="CodeSnippet" />
  <CodeSnippet code={CODE.CALL_EXAMPLE8} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <hr></hr>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`Leg`}</h2>
        <h4>{`Required parameters`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Attribute`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`periodStart`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`datetime`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The date and time in UTC, RFC3339 format (Y-m-d\\TH:i:sP) for the start of the reporting period.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`periodEnd`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`datetime`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The date and time in UTC, RFC3339 format (Y-m-d\\TH:i:sP) for the end of the reporting period. This must be after periodStart.`}</td>
            </tr>
          </tbody>
        </table>
        <h5>{`Optional parameters`}</h5>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Attribute`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`periodGroup`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The time granularity to group the statistics. Valid values are `}<inlineCode parentName="td">{`none, hour, day, week, month and year`}</inlineCode>{`, case insensitive. `}<strong parentName="td">{`Default: `}<inlineCode parentName="strong">{`day`}</inlineCode>{`.`}</strong></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`timezone`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The timezone to group and display the results in the time-dimension, such as “Europe/Amsterdam” or “US/Central”. All location names in the Time Zone Database are valid. `}<strong parentName="td">{`Default: `}<inlineCode parentName="strong">{`UTC`}</inlineCode>{`.`}</strong></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`groupBy`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string list`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Zero or more values from `}<inlineCode parentName="td">{`status, source, sourceCountry, sourceOperatorPrefix, sourceType, destination, destinationCountry, destinationOperatorPrefix, destinationType, direction, protocol`}</inlineCode>{`. More than one value can be specified by repeating the query parameter groupBy. For example, `}<inlineCode parentName="td">{`groupBy=status&groupBy=sourceCountry`}</inlineCode>{` groups items by status AND source country. `}<strong parentName="td">{`Default: empty.`}</strong></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`filterBy[]`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`You can specify multiple filters as explained below. Specifying the same type of filter multiple times with different values is considered a union query, which means that the reports contains all legs that match one of the conditions. Specifying multiple different filters results in an intersection query, for example `}<inlineCode parentName="td">{`filterBy[sourceCountryPrefix]=44&filterBy[status]=ended`}</inlineCode>{` only returns legs sent to the United Kingdom that were successful. `}<strong parentName="td">{`Default: empty.`}</strong></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`filterBy[source]`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A source that has been used for creating a leg, which can either be a phone number or an alphanumeric string. `}<strong parentName="td">{`Default: empty.`}</strong></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`filterBy[sourceCountryCode]`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`An ISO 639 country code, for example DE for Germany. More than one code can be provided by repeating the query parameter, such as `}<inlineCode parentName="td">{`filterBy[sourceCountryCode]=NL&filterBy[sourceCountryCode]=UK`}</inlineCode>{`. `}<strong parentName="td">{`Default: empty.`}</strong></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`filterBy[sourceCountryPrefix]`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`integer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A country phone number prefix, for example 91 for India. More than one prefix can be provided by repeating the query parameter. This will be translated to `}<inlineCode parentName="td">{`filterBy[sourceCountryCode]`}</inlineCode>{`. For example `}<inlineCode parentName="td">{`filterBy[sourceCountryPrefix]=31&filterBy[sourceCountryPrefix]=91`}</inlineCode>{` will be translated to `}<inlineCode parentName="td">{`filterBy[sourceCountryCode]=NL&filterBy[sourceCountryPrefix]=IN`}</inlineCode>{`. `}<strong parentName="td">{`Default: empty.`}</strong></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`filterBy[sourceOperatorPrefix]`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`integer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`An operator prefix, for example 86135 for a number belongs to China Mobile. More than one prefix can be provided by repeating the query parameter, such as `}<inlineCode parentName="td">{`filterBy[sourceOperatorPrefix]=86135&filterBy[sourceOperatorPrefix]=86139`}</inlineCode>{`. `}<strong parentName="td">{`Default: empty.`}</strong></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`filterBy[sourceType]`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Type of source number. Valid values are `}<inlineCode parentName="td">{`landline, mobile, paging, mobile_paging, mobile_landline, satellite, supplementary_services`}</inlineCode>{`, case insensitive. For example `}<inlineCode parentName="td">{`filterBy[sourceType]=landline`}</inlineCode>{`. `}<strong parentName="td">{`Default: empty.`}</strong></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`filterBy[destination]`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A destination that has been used for receiving a leg, which can either be a phone number or an alphanumeric receiver. `}<strong parentName="td">{`Default: empty.`}</strong></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`filterBy[destinationCountryCode]`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`An ISO 639 country code, for example DE for Germany. More than one code can be provided by repeating the query parameter. `}<strong parentName="td">{`Default: empty.`}</strong></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`filterBy[destinationCountryPrefix]`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`integer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A country phone number prefix, for example 91 for India. More than one prefix can be provided by repeating the query parameter. This will be translated to `}<inlineCode parentName="td">{`filterBy[destinationCountryCode]`}</inlineCode>{`. `}<strong parentName="td">{`Default: empty.`}</strong></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`filterBy[destinationOperatorPrefix]`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`integer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`An operator prefix, for example 86135 for a number belongs to China Mobile. More than one prefix can be provided by repeating the query parameter. `}<strong parentName="td">{`Default: empty.`}</strong></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`filterBy[destinationType]`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Type of destination number. Valid values are `}<inlineCode parentName="td">{`landline, mobile, paging, mobile_paging, mobile_landline, satellite, supplementary_services`}</inlineCode>{`, case insensitive. For example `}<inlineCode parentName="td">{`filterBy[destinationType]=landline`}</inlineCode>{`. `}<strong parentName="td">{`Default: empty.`}</strong></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`filterBy[direction]`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Direction of a leg, either `}<inlineCode parentName="td">{`incoming`}</inlineCode>{` or `}<inlineCode parentName="td">{`outgoing`}</inlineCode>{`, case insensitive. For example `}<inlineCode parentName="td">{`filterBy[direction]=incoming`}</inlineCode>{`. `}<strong parentName="td">{`Default: empty.`}</strong></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`filterBy[protocol]`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A leg protocol. Valid values are `}<inlineCode parentName="td">{`did`}</inlineCode>{`, `}<inlineCode parentName="td">{`sip`}</inlineCode>{`, `}<inlineCode parentName="td">{`webrtc`}</inlineCode>{`, case insensitive. More than one type of protocol can be provided by repeating the query parameter. `}<strong parentName="td">{`Default: empty.`}</strong></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`filterBy[status]`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A leg status. See the Leg Status table for possible values. More than one status can be provided by repeating the query parameter. `}<strong parentName="td">{`Default: empty.`}</strong></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`sort`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Specify sort criteria with the following syntax: `}<inlineCode parentName="td">{`key1:direction1[,key2:direction2]`}</inlineCode>{`. If a direction is omitted, asc (ascending) is assumed. Valid keys are `}<inlineCode parentName="td">{`count, successCount, failureCount, totalDuration, averageDuration, successRate, failureRate`}</inlineCode>{`. Valid directions are `}<inlineCode parentName="td">{`asc, desc`}</inlineCode>{`. The appearance order of more than one key will be preserved when sorting a result. Further, the sorted result will be sorted by time in ascending order. For example, `}<inlineCode parentName="td">{`sort=count:desc`}</inlineCode>{` will sort results by `}<inlineCode parentName="td">{`count`}</inlineCode>{` in descending order. And the items having the same sorting key values will be sorted in time ascending order if `}<inlineCode parentName="td">{`periodGroup`}</inlineCode>{` is not none. `}<strong parentName="td">{`Default: empty.`}</strong></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`offset`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`integer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The number of elements to skip. `}<strong parentName="td">{`Default: 0, skipping zero element.`}</strong></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`limit`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`integer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The number of elements to return. Valid values are any integer number between 1 and 2500, both inclusive. A number larger than 2500 will be clipped to 2500. `}<strong parentName="td">{`Default: 500.`}</strong></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`ref`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A string to ensure consistency of pagination. When accessing the first part of result, it will be included in the response. To access further parts it needs to be added as a request query parameter.`}</td>
            </tr>
          </tbody>
        </table>
        <p>{`The desired output format can be specified with the Accept header. Valid values are `}<inlineCode parentName="p">{`application/json`}</inlineCode>{` (default) and `}<inlineCode parentName="p">{`text/csv`}</inlineCode>{`.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Available HTTP Methods</h4>
  <CodeSnippet code={CODE.LEG_METHODS} mdxType="CodeSnippet" />  
  <h4>Example Request</h4>
  <CodeSnippet code={CODE.LEG_EXAMPLE} mdxType="CodeSnippet" />  
  <h4>Example Response</h4>
  <CodeSnippet code={CODE.LEG_RESPONSE} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <h4>{`The Leg Status table`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Status`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`starting`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A leg is being established.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ringing`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A leg is awaiting to be picked up.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ongoing`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A leg is happening now.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`hangup`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A leg has been finished successfully.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`busy`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A leg could not be established as the endpoint was busy.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`no_answer`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A leg was not picked up.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`failed`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An error has occurred when establishing a leg.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Leg Report object`}</h3>
    <p>{`This object represents a statistic.`}</p>
    <h4>{`Attributes`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`items`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`array`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Legs are split by provided grouping fields. Each item corresponds to statistics of a group.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`items[].timestamp`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`datetime`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The timestamp in RFC3339 format (Y-m-d\\TH:i:sP). The first second of hour / day / week / month / year will be used to represent the period group. For example, `}<inlineCode parentName="td">{`2018-08-06T00:00:00Z`}</inlineCode>{` represents the 32th week in 2018 or the first hour of the day August 6, 2018 or the day itself.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`items[].leg`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`object`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contains details about the legs based on the groupBy parameters. If no groupBy parameter was specified this object will be empty.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`items[].totalDuration`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The total duration (seconds) of legs in a group.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`items[].averageDuration`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`float`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The average duration (seconds) of legs in a group.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`items[].count`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The total number of legs in a group.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`items[].successCount`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The number of successful legs in a group.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`items[].failureCount`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The number of failed legs in a group.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`items[].successRate`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`float`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The average rate of successful legs in a group.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`items[].failureRate`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`float`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The average rate of failed legs in a group.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ref`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A string to ensure consistency of pagination. A ref expires one day after creation and its lifetime cannot be extended.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`totalCount`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The total number of items that can be retrieved for this request through pagination.`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`The leg Object`}</h4>
    <p>{`A call has legs which are incoming or outgoing voice connections. An incoming leg is created when somebody calls a number. Outgoing legs are created when a call is transferred.`}</p>
    {/* A leg represents any (attempted) connection between two numbers. A number could be a common telephone number purchased from a telecommunication operator, e.g., KPN, or a Skype account or any other kind of account that could make or receive a leg. This means if a user's leg to your number is forwarded to another one by us, we create a new leg between the user's number and the new number. */}
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`source`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Endpoint object`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Source of a leg.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`destination`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Endpoint object`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Destination of a leg.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`direction`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Direction of a leg, either `}<inlineCode parentName="td">{`incoming`}</inlineCode>{` or `}<inlineCode parentName="td">{`outgoing`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`protocol`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A leg protocol. Possible values are `}<inlineCode parentName="td">{`did`}</inlineCode>{`, `}<inlineCode parentName="td">{`sip`}</inlineCode>{`, `}<inlineCode parentName="td">{`webrtc`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`status`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The status of legs in a group. See the Leg Status table.`}</td>
        </tr>
      </tbody>
    </table>
    <h5>{`The Endpoint object`}</h5>
    <p>{`An endpoint is an object stores information about a number.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`number`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Number of the endpoint, for example 31648888888.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`countryCode`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Country code of the endpoint. Exists only when group by country.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`operatorPrefix`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Operator prefix of the endpoint. Exists only when group by operator prefix.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`type`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Type of the endpoint. Exists only when group by type.`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`CSV format response fields`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`timestamp`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`datetime`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The date and time in RFC3339 format (Y-m-d\\TH:i:sP). The first second of hour / day / week / month / year will be used to represent the period group. For example, `}<inlineCode parentName="td">{`2018-04-09T00:00:00Z`}</inlineCode>{` represents the 15th week in 2018 or the first hour of the day April 9, 2018 or the day itself. Exists only when periodGroup is not none.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`source`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Source number of a leg. Exists only when group by source.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`sourceCountryCode`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Country code of the source of a leg. Exists only when group by source country.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`sourceOperatorPrefix`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Operator prefix of the source of a leg.  Exists only when group by operator prefix.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`sourceType`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Type of the source of a leg. Exists only when group by source type.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`destination`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Destination number of a leg. Exists only when group by destination. Exists only when group by destination.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`destinationCountryCode`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Country code of the destination of a leg. Exists only when group by destination country.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`destinationOperatorPrefix`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Operator prefix of the destination of a leg. Exists only when group by destination operator prefix.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`destinationType`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Type of the destination of a leg. Exists only when group by destination type.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`direction`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Direction of a leg, either `}<inlineCode parentName="td">{`incoming`}</inlineCode>{` or `}<inlineCode parentName="td">{`outgoing`}</inlineCode>{`. Exists only when group by direction.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`protocol`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A leg protocol. Possible values are `}<inlineCode parentName="td">{`did`}</inlineCode>{`, `}<inlineCode parentName="td">{`sip`}</inlineCode>{`, `}<inlineCode parentName="td">{`webrtc`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`status`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The status of legs in a group. See the Leg Status table. Exists only when group by status.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`totalDuration`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The total duration (seconds) of legs in a group.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`averageDuration`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`float`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The average duration (seconds) of legs in a group.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`count`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The total number of legs in a group.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`successCount`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The number of successful legs in a group.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`failureCount`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The number of failed legs in a group.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`successRate`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`float`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The average rate of successful legs in a group.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`failureRate`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`float`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The average rate of failed legs in a group.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Leg Example requests`}</h3>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h4>{`Monthly legs grouped by country`}</h4>
        <p>{`Group all legs made in August, 2018 UTC+8 by source country to see legs originated from every country in August.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Monthly legs grouped by country</h4>
  <CodeSnippet code={CODE.LEG_EXAMPLE1} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h4>{`Daily legs grouped by country`}</h4>
        <p>{`Group all legs made in August, 2018 UTC+8 by source country and day to see daily legs originated from every country.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Daily legs grouped by country</h4>
  <CodeSnippet code={CODE.LEG_EXAMPLE2} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h4>{`Daily legs originated from the Netherlands`}</h4>
        <p>{`Group all legs made in August, 2018 UTC+8 from the Netherlands by day to see daily legs originated from the Netherlands.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Daily legs originated from the Netherlands</h4>
  <CodeSnippet code={CODE.LEG_EXAMPLE3} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h4>{`Daily legs originated from the Netherlands and Germany`}</h4>
        <p>{`Group all legs made in August, 2018 UTC+8 from the Netherlands and Germany by day to see daily legs originated from the two countries.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Daily legs originated from the Netherlands and Germany</h4>
  <CodeSnippet code={CODE.LEG_EXAMPLE4} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h4>{`Daily failed legs originated from the Netherlands`}</h4>
        <p>{`Group all legs made in August, 2018 UTC+8 from the Netherlands by day to see daily legs originated from the Netherlands.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Daily failed legs originated from the Netherlands</h4>
  <CodeSnippet code={CODE.LEG_EXAMPLE5} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h4>{`Daily distribution of leg types originated from the Netherlands`}</h4>
        <p>{`Group all legs made in August, 2018 UTC+8 from the Netherlands by day and sourceType to see daily distribution of leg types originated from the Netherlands.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Daily distribution of leg types originated from the Netherlands</h4>
  <CodeSnippet code={CODE.LEG_EXAMPLE6} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h4>{`Iterate over all items`}</h4>
        <p>{`Requesting the first part gives us the first 100 items and `}<inlineCode parentName="p">{`ref: 1d192ee0b10111e896f8529269fb1459`}</inlineCode>{`. In order to get the second part, run the second request.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Iterate over all items</h4>
  <CodeSnippet code={CODE.LEG_EXAMPLE7} mdxType="CodeSnippet" />
  <CodeSnippet code={CODE.LEG_EXAMPLE8} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <hr></hr>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <hr></hr>
        <h2>{`Conversations Message`}</h2>
        <h4>{`Required parameters`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Attribute`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`periodStart`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Timestamp`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The date and time in UTC, RFC3339 format (Y-m-d\\TH:i:sP) for the start of the reporting period.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`periodEnd`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Timestamp`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The date and time in UTC, RFC3339 format (Y-m-d\\TH:i:sP) for the end of the reporting period. This must be after periodStart.`}</td>
            </tr>
          </tbody>
        </table>
        <h4>{`Optional parameters`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Attribute`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`periodGroup`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The time granularity to group the statistics. Valid values are `}<inlineCode parentName="td">{`none, hour, day, week, month, year`}</inlineCode>{`. `}<strong parentName="td">{`Default: `}<inlineCode parentName="strong">{`day`}</inlineCode>{`.`}</strong></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`timezone`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The timezone to group and display the results in the time-dimension, such as “Europe/Amsterdam” or “US/Central”. All location names in the IANA Time Zone Database are valid. `}<strong parentName="td">{`Default: `}<inlineCode parentName="strong">{`UTC`}</inlineCode>{`.`}</strong></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`groupBys`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Zero or more values from `}<inlineCode parentName="td">{`channel, platform, direction, status, type`}</inlineCode>{`. More than one value can be specified by repeating the query parameter. For example, `}<inlineCode parentName="td">{`groupBy=status&groupBy=platform`}</inlineCode>{` groups items by status AND direction.  `}<strong parentName="td">{`Default: empty.`}</strong></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`filterBy`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`PublicFilter`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`You can specify multiple filters as explained in `}<a parentName="td" {...{
                  "href": "/api/reporting#conversations-public-filter"
                }}>{`Filter`}</a>{` table. Specifying the same type of filter multiple times with different values is considered a union query, which means that the reports contains all messages that match one of the conditions. Specifying multiple different filters results in an intersection query, for example `}<inlineCode parentName="td">{`filterBy[type]=text&filterBy[status]=delivered`}</inlineCode>{` only returns messages only delivered messages of type text. `}<strong parentName="td">{`Default: empty.`}</strong></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`sort`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Specify sort criteria with the following syntax: `}<inlineCode parentName="td">{`key1:direction1[,key2:direction2]`}</inlineCode>{`. If a direction is omitted, asc (ascending) is assumed. Valid keys are `}<inlineCode parentName="td">{`count`}</inlineCode>{`. Valid directions are `}<inlineCode parentName="td">{`asc, desc`}</inlineCode>{`. The appearance order of more than one key will be preserved when sorting a result. Further, the sorted result will be sorted by time in ascending order. For example, `}<inlineCode parentName="td">{`sort=count:desc`}</inlineCode>{` will sort results by `}<inlineCode parentName="td">{`count`}</inlineCode>{` in descending order. And the items having the same count will be sorted in time ascending order.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`offset`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`uint32`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The number of elements to skip. `}<strong parentName="td">{`Default: 0, skipping zero element.`}</strong></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`limit`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`uint32`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The number of elements to return. Valid values are any integer number between 1 and 2500, both inclusive. A number larger than 2500 will be clipped to 2500. `}<strong parentName="td">{`Default: 500.`}</strong></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`ref`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A string to ensure consistency of pagination. When accessing the first part of result, it will be included in the response. To access further parts it needs to be added as a request query parameter.`}</td>
            </tr>
          </tbody>
        </table>
        <h4><a name="conversations-public-filter"></a>{`PublicFilter`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Attribute`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`direction`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Direction of a message, either `}<inlineCode parentName="td">{`sent`}</inlineCode>{` or `}<inlineCode parentName="td">{`received`}</inlineCode>{`, case insensitive. For example `}<inlineCode parentName="td">{`filterBy[direction]=sent`}</inlineCode>{`. `}<strong parentName="td">{`Default: empty.`}</strong></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`status`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Status of a message, valid values are `}<inlineCode parentName="td">{`deleted`}</inlineCode>{`, `}<inlineCode parentName="td">{`delivered`}</inlineCode>{`, `}<inlineCode parentName="td">{`failed`}</inlineCode>{`, `}<inlineCode parentName="td">{`pending`}</inlineCode>{`, `}<inlineCode parentName="td">{`read`}</inlineCode>{`, `}<inlineCode parentName="td">{`received`}</inlineCode>{`, `}<inlineCode parentName="td">{`rejected`}</inlineCode>{`, `}<inlineCode parentName="td">{`sent`}</inlineCode>{`, `}<inlineCode parentName="td">{`unsupported`}</inlineCode>{`, case insensitive. More than one type of protocol can be provided by repeating the query parameter, for example: `}<inlineCode parentName="td">{`filterBy[status]=sent&filterBy[status]=delivered`}</inlineCode>{` `}<strong parentName="td">{`Default: empty.`}</strong></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`type`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Type of a message, valid values are `}<inlineCode parentName="td">{`text`}</inlineCode>{`, `}<inlineCode parentName="td">{`image`}</inlineCode>{`, `}<inlineCode parentName="td">{`audio`}</inlineCode>{`, `}<inlineCode parentName="td">{`video`}</inlineCode>{`, `}<inlineCode parentName="td">{`location`}</inlineCode>{`, `}<inlineCode parentName="td">{`file`}</inlineCode>{`, `}<inlineCode parentName="td">{`hsm`}</inlineCode>{` (only for WhatsApp), `}<inlineCode parentName="td">{`unknown`}</inlineCode>{`, case insensitive. More than one type of protocol can be provided by repeating the query parameter. `}<strong parentName="td">{`Default: empty.`}</strong></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`channel_id`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Id of channel. More than one type of protocol can be provided by repeating the query parameter. `}<strong parentName="td">{`Default: empty.`}</strong></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`platform`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Platform name. More than one type of protocol can be provided by repeating the query parameter. `}<strong parentName="td">{`Default: empty.`}</strong></td>
            </tr>
          </tbody>
        </table>
        <p>{`The desired output format can be specified with the Accept header. Valid values are `}<inlineCode parentName="p">{`application/json`}</inlineCode>{` (default) and `}<inlineCode parentName="p">{`text/csv`}</inlineCode>{`.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Available HTTP Methods</h4>
  <CodeSnippet code={CODE.CONVO_METHODS} mdxType="CodeSnippet" />  
  <h4>Example Request</h4>
  <CodeSnippet code={CODE.CONVO_EXAMPLE} mdxType="CodeSnippet" />  
  <h4>Example Response</h4>
  <CodeSnippet code={CODE.CONVO_RESPONSE} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <h3>{`Conversations Message report object`}</h3>
    <p>{`This object represents a statistic.`}</p>
    <h4>{`Response object`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`items`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`MessageResponseItem`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Messages are split by provided grouping fields. Each item corresponds to statistics of a group.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ref`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A string to ensure consistency of pagination. A ref expires one day after creation and its lifetime cannot be extended.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`total_count`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`uint64`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The total number of items that can be retrieved for this request through pagination.`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`MessageResponseItem object`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`timestamp`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The timestamp in RFC3339 format (Y-m-d\\TH:i:sP). The first second of hour / day / week / month / year will be used to represent the period group. For example, `}<inlineCode parentName="td">{`2018-04-09T00:00:00Z`}</inlineCode>{` represents the 15th week in 2018 or the first hour of the day April 9, 2018 or the day itself.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`message`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`MessageItem`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contains details about the messages based on the groupBy parameters. If no groupBy parameter was specified this object will be empty.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`count`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`uint64`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The total number of messages in a group.`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`MessageItem object`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`direction`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Direction of a message, either `}<inlineCode parentName="td">{`sent`}</inlineCode>{` or `}<inlineCode parentName="td">{`received`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`platform`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Message platform name.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`status`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Status of a message, valid values are `}<inlineCode parentName="td">{`deleted`}</inlineCode>{`, `}<inlineCode parentName="td">{`delivered`}</inlineCode>{`, `}<inlineCode parentName="td">{`failed`}</inlineCode>{`, `}<inlineCode parentName="td">{`pending`}</inlineCode>{`, `}<inlineCode parentName="td">{`read`}</inlineCode>{`, `}<inlineCode parentName="td">{`received`}</inlineCode>{`, `}<inlineCode parentName="td">{`rejected`}</inlineCode>{`, `}<inlineCode parentName="td">{`sent`}</inlineCode>{`, `}<inlineCode parentName="td">{`unsupported`}</inlineCode>{`, `}<inlineCode parentName="td">{`opened`}</inlineCode>{`, `}<inlineCode parentName="td">{`clicked`}</inlineCode>{`, `}<inlineCode parentName="td">{`accepted`}</inlineCode>{`, `}<inlineCode parentName="td">{`bounce`}</inlineCode>{`, `}<inlineCode parentName="td">{`spam_compliant`}</inlineCode>{`, `}<inlineCode parentName="td">{`out_of_bound`}</inlineCode>{`, `}<inlineCode parentName="td">{`delayed`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`type`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Type of a message, valid values are `}<inlineCode parentName="td">{`text`}</inlineCode>{`, `}<inlineCode parentName="td">{`image`}</inlineCode>{`, `}<inlineCode parentName="td">{`audio`}</inlineCode>{`, `}<inlineCode parentName="td">{`video`}</inlineCode>{`, `}<inlineCode parentName="td">{`location`}</inlineCode>{`, `}<inlineCode parentName="td">{`file`}</inlineCode>{`, `}<inlineCode parentName="td">{`hsm`}</inlineCode>{` (only for WhatsApp), `}<inlineCode parentName="td">{`unknown`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`channel`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Message channel id.`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`Email Statuses`}</h4>
    <p>{`The following email statuses could be received from the Conversations API.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Status Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`pending`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Message is sent to an email provider.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`accepted`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Message is accepted by the email provider.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`sent`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Remote mail transfer agent acknowledged a message.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`opened`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Recipient has opened the email. It only works for html content.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`clicked`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Recipient clicked a tracked link in the email. It only works for html content with `}<inlineCode parentName="td">{`href`}</inlineCode>{` defined links.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`bounce`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Remote mail transfer agent has rejected the email.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`spam_complaint`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Message is classified as spam by the recipient.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`out_of_bounded`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Message is not delivered to recipient.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`delayed`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Remote mail transfer agent is rejected the email temporarily.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`received`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Message is received from sender.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>
    <h4>{`CSV format response fields`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`count`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The total number of messages in a group.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`timestamp`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`datetime`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The date and time in RFC3339 format (Y-m-d\\TH:i:sP). The first second of hour / day / week / month / year will be used to represent the period group. For example, `}<inlineCode parentName="td">{`2018-04-09T00:00:00Z`}</inlineCode>{` represents the 15th week in 2018 or the first hour of the day April 9, 2018 or the day itself. Exists only when periodGroup is not none.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`direction`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Direction of a message, either `}<inlineCode parentName="td">{`sent`}</inlineCode>{` or `}<inlineCode parentName="td">{`received`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`status`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Status of a message, valid values are `}<inlineCode parentName="td">{`deleted`}</inlineCode>{`, `}<inlineCode parentName="td">{`delivered`}</inlineCode>{`, `}<inlineCode parentName="td">{`failed`}</inlineCode>{`, `}<inlineCode parentName="td">{`pending`}</inlineCode>{`, `}<inlineCode parentName="td">{`read`}</inlineCode>{`, `}<inlineCode parentName="td">{`received`}</inlineCode>{`, `}<inlineCode parentName="td">{`rejected`}</inlineCode>{`, `}<inlineCode parentName="td">{`sent`}</inlineCode>{`, `}<inlineCode parentName="td">{`unsupported`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`type`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Type of a message, valid values are `}<inlineCode parentName="td">{`text`}</inlineCode>{`, `}<inlineCode parentName="td">{`image`}</inlineCode>{`, `}<inlineCode parentName="td">{`audio`}</inlineCode>{`, `}<inlineCode parentName="td">{`video`}</inlineCode>{`, `}<inlineCode parentName="td">{`location`}</inlineCode>{`, `}<inlineCode parentName="td">{`file`}</inlineCode>{`, `}<inlineCode parentName="td">{`hsm`}</inlineCode>{` (only for WhatsApp), `}<inlineCode parentName="td">{`unknown`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`platform`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Message platform name.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`channel`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Message channel id.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Conversations Message Example requests`}</h3>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h4>{`Monthly messages grouped by status`}</h4>
        <p>{`Group all messages made in August, 2018 UTC+8 by status.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Monthly messages grouped by status</h4>
  <CodeSnippet code={CODE.CONVO_EXAMPLE1} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <hr></hr>
        <h2>{`Flow`}</h2>
        <h4>{`Required parameters`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Attribute`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`periodStart`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`datetime`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The date and time in UTC, RFC3339 format (Y-m-d\\TH:i:sP) for the start of the reporting period.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`periodEnd`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`datetime`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The date and time in UTC, RFC3339 format (Y-m-d\\TH:i:sP) for the end of the reporting period. This must be after periodStart.`}</td>
            </tr>
          </tbody>
        </table>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Available HTTP Methods</h4>
  <CodeSnippet code={CODE.FLOW_METHODS} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <h4>{`Optional parameters`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`periodGroup`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The time granularity to group the statistics. Valid values are `}<inlineCode parentName="td">{`none, hour, day, week, month, year`}</inlineCode>{`. `}<strong parentName="td">{`Default: `}<inlineCode parentName="strong">{`day`}</inlineCode>{`.`}</strong></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`timezone`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The timezone to group and display the results in the time-dimension, such as “Europe/Amsterdam” or “US/Central”. All location names in the IANA Time Zone Database are valid. `}<strong parentName="td">{`Default: `}<inlineCode parentName="strong">{`UTC`}</inlineCode>{`.`}</strong></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`groupBy`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string list`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Zero or more values from `}<inlineCode parentName="td">{`trigger`}</inlineCode>{`. More than one value can be specified by repeating the query parameter. For example, `}<inlineCode parentName="td">{`groupBy=trigger&groupBy=XXXXX`}</inlineCode>{` groups items by triggerType AND XXXXX. `}<strong parentName="td">{`Default: empty.`}</strong></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`filterBy[]`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`You can specify multiple filters as explained below. Specifying the same type of filter multiple times with different values is considered a union query, which means that the reports contains all invocations that match one of the conditions. Specifying multiple different filters results in an intersection query, for example `}<inlineCode parentName="td">{`filterBy[triggerType]=received_sms&filterBy[id]=123e4567e89b12d3a456426655440000`}</inlineCode>{` only returns invocation which failed and have a flowId of 123e4567e89b12d3a456426655440000. `}<strong parentName="td">{`Default: empty.`}</strong></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`filterBy[id]`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The UUID of the flow. It remains unchanged during its lifecycle. `}<strong parentName="td">{`Default: empty.`}</strong></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`filterBy[trigger]`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An action that caused a invocation to be triggered.  `}<strong parentName="td">{`Default: empty.`}</strong></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`sort`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Specify sort criteria with the following syntax: `}<inlineCode parentName="td">{`key1:direction1[,key2:direction2]`}</inlineCode>{`. If a direction is omitted, asc (ascending) is assumed. Valid keys are `}<inlineCode parentName="td">{`count`}</inlineCode>{`. Valid directions are `}<inlineCode parentName="td">{`asc, desc`}</inlineCode>{`. The appearance order of more than one key will be preserved when sorting a result. Further, the sorted result will be sorted by time in ascending order. For example, `}<inlineCode parentName="td">{`sort=count:desc`}</inlineCode>{` will sort results by count in descending order. And the items having the same sorting key values will be sorted in time ascending order if `}<inlineCode parentName="td">{`periodGroup`}</inlineCode>{` is not none. `}<strong parentName="td">{`Default: empty.`}</strong></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`offset`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The number of elements to skip. `}<strong parentName="td">{`Default: 0, skipping zero element.`}</strong></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`limit`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The number of elements to return. Valid values are any integer number between 1 and 2500, both inclusive. A number larger than 2500 will be clipped to 2500. `}<strong parentName="td">{`Default: 500.`}</strong></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ref`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A string to ensure consistency of pagination. When accessing the first part of result, it will be included in the response. To access further parts it needs to be added as a request query parameter.`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`The desired output format can be specified with the Accept header. Valid values are `}<inlineCode parentName="p">{`application/json`}</inlineCode>{` (default) and `}<inlineCode parentName="p">{`text/csv`}</inlineCode>{`.`}</p>
    <h4>{`The trigger table`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Trigger`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`on_received_sms`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A flow triggered by an sms.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`on_incoming_voice_call`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A flow triggered by an phonecall.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`on_received_http_request`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A flow triggered by an webhook.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`on_received_conversation_message`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A flow triggered by an omnichannel conversation message.`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`The flow Object`}</h4>
    <p>{`A flow describes the set of steps that will be executed when a flow is invoked.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`trigger`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The thing/event that will lead to a flow being invoked. Exist only when group by trigger.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Flow Report object`}</h3>
    <p>{`This object represents a statistic.`}</p>
    <h4>{`Attributes`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`items`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`array`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Flows are split by provided grouping fields. Each item corresponds to statistics of a group.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`items[].timestamp`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`datetime`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The timestamp in RFC3339 format (Y-m-d\\TH:i:sP). The first second of hour / day / week / month / year will be used to represent the period group. For example, `}<inlineCode parentName="td">{`2018-08-06T00:00:00Z`}</inlineCode>{` represents the 32th week in 2018 or the first hour of the day August 6, 2018 or the day itself.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`items[].flow`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`object`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contains details about the flows based on the groupBy parameters. If no groupBy parameter was specified this object will be empty.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`items[].count`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The number of items in a group.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`totalCount`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The total number of items that can be retrieved for this request through pagination.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ref`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A string to ensure consistency of pagination. A ref expires one day after creation and its lifetime cannot be extended.`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`CSV format response fields`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`timestamp`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`datetime`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The date and time in RFC3339 format (Y-m-d\\TH:i:sP). The first second of hour / day / week / month / year will be used to represent the period group. For example, `}<inlineCode parentName="td">{`2018-04-09T00:00:00Z`}</inlineCode>{` represents the 15th week in 2018 or the first hour of the day April 9, 2018 or the day itself. Exists only when periodGroup is not none.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`trigger`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An action that caused an invocation to be triggered. Exist only when group by triggerType`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`count`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The total number of items in a group.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Flow Example requests`}</h3>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h4>{`Invocations grouped by trigger type`}</h4>
        <p>{`Group all flows made in August, 2018 UTC+8 by trigger type to see one month flows for the trigger types.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Invocations grouped by trigger type</h4>
  <CodeSnippet code={CODE.FLOW_EXAMPLE1} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h4>{`Daily invocations grouped by trigger type`}</h4>
        <p>{`Group all flows made in August, 2018 UTC+8 by trigger type and day to see daily flows for the trigger types.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Daily invocations grouped by trigger type</h4>
  <CodeSnippet code={CODE.FLOW_EXAMPLE2} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h4>{`Iterate over all items`}</h4>
        <p>{`Requesting the first part gives us the first 100 items and `}<inlineCode parentName="p">{`ref: 03aa7194b10111e88981529269fb1459`}</inlineCode>{`. In order to get the second part, run the second request.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Daily invocations grouped by trigger type</h4>
  <CodeSnippet code={CODE.FLOW_EXAMPLE3} mdxType="CodeSnippet" />
  <CodeSnippet code={CODE.FLOW_EXAMPLE4} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <hr></hr>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`Invocation`}</h2>
        <h4>{`Required parameters`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Attribute`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`periodStart`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`datetime`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The date and time in UTC, RFC3339 format (Y-m-d\\TH:i:sP) for the start of the reporting period.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`periodEnd`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`datetime`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The date and time in UTC, RFC3339 format (Y-m-d\\TH:i:sP) for the end of the reporting period. This must be after periodStart.`}</td>
            </tr>
          </tbody>
        </table>
        <h4>{`Optional parameters`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Attribute`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`periodGroup`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The time granularity to group the statistics. Valid values are `}<inlineCode parentName="td">{`none, hour, day, week, month, year`}</inlineCode>{`. `}<strong parentName="td">{`Default: `}<inlineCode parentName="strong">{`day`}</inlineCode>{`.`}</strong></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`timezone`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The timezone to group and display the results in the time-dimension, such as “Europe/Amsterdam” or “US/Central”. All location names in the IANA Time Zone Database are valid. `}<strong parentName="td">{`Default: `}<inlineCode parentName="strong">{`UTC`}</inlineCode>{`.`}</strong></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`groupBy`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string list`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Zero or more values from `}<inlineCode parentName="td">{`status, flowId, flowTrigger, source, target`}</inlineCode>{`. More than one value can be specified by repeating the query parameter. For example, `}<inlineCode parentName="td">{`groupBy=status&groupBy=flowId`}</inlineCode>{` groups items by status AND flowId. `}<strong parentName="td">{`Default: empty.`}</strong></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`filterBy[]`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`You can specify multiple filters as explained below. Specifying the same type of filter multiple times with different values is considered a union query, which means that the reports contains all invocations that match one of the conditions. Specifying multiple different filters results in an intersection query, for example `}<inlineCode parentName="td">{`filterBy[status]=failed&filterBy[flowId]=123e4567e89b12d3a456426655440000`}</inlineCode>{` only returns invocation which failed and have a flowId of 123e4567e89b12d3a456426655440000. `}<strong parentName="td">{`Default: empty.`}</strong></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`filterBy[flowId]`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The UUID of the flow. It remains unchanged during its lifecycle. `}<strong parentName="td">{`Default: empty.`}</strong></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`filterBy[status]`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Status of an invocation. Valid values are `}<inlineCode parentName="td">{`in_progress`}</inlineCode>{`, `}<inlineCode parentName="td">{`finished`}</inlineCode>{` and `}<inlineCode parentName="td">{`failed`}</inlineCode>{`, case insensitive.  `}<strong parentName="td">{`Default: empty.`}</strong></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`filterBy[flowTrigger]`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`An action that caused a invocation to be triggered.  `}<strong parentName="td">{`Default: empty.`}</strong></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`filterBy[source]`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The source of the invocation, e.g. the originator (phone number) of a received SMS, or some other identifier related to the trigger. `}<strong parentName="td">{`Default: empty.`}</strong></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`filterBy[target]`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The target of the invocation, e.g. the recipient (VMN) of a received SMS, or some other identifier related to the trigger. `}<strong parentName="td">{`Default: empty.`}</strong></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`sort`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Specify sort criteria with the following syntax: `}<inlineCode parentName="td">{`key1:direction1[,key2:direction2]`}</inlineCode>{`. If a direction is omitted, asc (ascending) is assumed. Valid keys are `}<inlineCode parentName="td">{`count, successCount, failureCount, successRate, failureRate`}</inlineCode>{`. Valid directions are `}<inlineCode parentName="td">{`asc, desc`}</inlineCode>{`. The appearance order of more than one key will be preserved when sorting a result. Further, the sorted result will be sorted by time in ascending order. For example, `}<inlineCode parentName="td">{`sort=count:desc`}</inlineCode>{` will sort results by count in descending order. And the items having the same sorting key values will be sorted in time ascending order if `}<inlineCode parentName="td">{`periodGroup`}</inlineCode>{` is not none. `}<strong parentName="td">{`Default: empty.`}</strong></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`offset`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`integer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The number of elements to skip. `}<strong parentName="td">{`Default: 0, skipping zero element.`}</strong></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`limit`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`integer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The number of elements to return. Valid values are any integer number between 1 and 2500, both inclusive. A number larger than 2500 will be clipped to 2500. `}<strong parentName="td">{`Default: 500.`}</strong></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`ref`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A string to ensure consistency of pagination. When accessing the first part of result, it will be included in the response. To access further parts it needs to be added as a request query parameter.`}</td>
            </tr>
          </tbody>
        </table>
        <p>{`The desired output format can be specified with the Accept header. Valid values are `}<inlineCode parentName="p">{`application/json`}</inlineCode>{` (default) and `}<inlineCode parentName="p">{`text/csv`}</inlineCode>{`.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Available HTTP Methods</h4>
  <CodeSnippet code={CODE.INVOCATION_METHODS} mdxType="CodeSnippet" />  
  <h4>Example Request</h4>
  <CodeSnippet code={CODE.INVOCATION_EXAMPLE} mdxType="CodeSnippet" />  
  <h4>Example Response</h4>
  <CodeSnippet code={CODE.INVOCATION_RESPONSE} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <h4>{`The flowTrigger table`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Trigger`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`on_received_sms`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A flow triggered by an sms.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`on_incoming_voice_call`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A flow triggered by an phone call.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`on_received_http_request`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A flow triggered by an webhook.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`on_received_conversation_message`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A flow triggered by an omnichannel conversation message.`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`The status table`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Status`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`in_progress`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An invocation is in progress.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`finished`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An invocation has finished.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`failed`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An invocation has failed.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`cancelled`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An invocation has cancelled.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`expired`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An invocation has expired.`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`The invocation Object`}</h4>
    <p>{`An invocation describes a flow being invoked by a user.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`status`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Status of an invocation. Exist only when group by status.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`flowId`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Id of the flow the invocation refers. Exist only when group by flowId.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`flowTrigger`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An action that caused a invocation to be triggered. Exist only when group by flowTrigger.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Invocation Report object`}</h3>
    <p>{`This object represents a statistic.`}</p>
    <h4>{`Attributes`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`items`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`array`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Invocations are split by provided grouping fields. Each item corresponds to statistics of a group.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`items[].timestamp`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`datetime`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The timestamp in RFC3339 format (Y-m-d\\TH:i:sP). The first second of hour / day / week / month / year will be used to represent the period group. For example, `}<inlineCode parentName="td">{`2018-08-06T00:00:00Z`}</inlineCode>{` represents the 32th week in 2018 or the first hour of the day August 6, 2018 or the day itself.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`items[].invocation`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`object`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contains details about the invocations based on the groupBy parameters. If no groupBy parameter was specified this object will be empty.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`items[].successCount`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The number of successful invocations in a group.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`items[].failureCount`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The number of failed invocations in a group.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`items[].successRate`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`float`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The average rate of successful invocations in a group.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`items[].failureRate`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`float`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The average rate of failed invocations in a group.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`items[].count`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The number of items in a group.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`totalCount`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The total number of items that can be retrieved for this request through pagination.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ref`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A string to ensure consistency of pagination. A ref expires one day after creation and its lifetime cannot be extended.`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`CSV format response fields`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`timestamp`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`datetime`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The date and time in RFC3339 format (Y-m-d\\TH:i:sP). The first second of hour / day / week / month / year will be used to represent the period group. For example, `}<inlineCode parentName="td">{`2018-04-09T00:00:00Z`}</inlineCode>{` represents the 15th week in 2018 or the first hour of the day April 9, 2018 or the day itself. Exists only when periodGroup is not none.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`status`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Status of an invocation. Exist only when group by status`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`flowId`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The UUID of the flow. It remains unchanged during its lifecycle. Exist only when group by flowId`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`flowTrigger`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An action that caused a invocation to be triggered. Exist only when group by flowTrigger`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`count`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The total number of items in a group.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`successCount`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The number of successful invocations in a group.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`failureCount`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The number of failed invocations in a group.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`successRate`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`float`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The average rate of successful invocations in a group.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`failureRate`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`float`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The average rate of failed invocations in a group.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Invocation Example requests`}</h3>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h4>{`Invocations grouped by trigger type`}</h4>
        <p>{`Group all invocations made in August, 2018 UTC+8 by flow trigger to see one month invocations for the trigger types.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Invocations grouped by trigger type</h4>
  <CodeSnippet code={CODE.INVOCATION_EXAMPLE1} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h4>{`Daily invocations grouped by trigger type`}</h4>
        <p>{`Group all invocations made in August, 2018 UTC+8 by flow trigger and day to see daily invocations for the trigger types.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Daily invocations grouped by trigger type</h4>
  <CodeSnippet code={CODE.INVOCATION_EXAMPLE2} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h4>{`Iterate over all items`}</h4>
        <p>{`Requesting the first part gives us the first 100 items and `}<inlineCode parentName="p">{`ref: 03aa7194b10111e88981529269fb1459`}</inlineCode>{`. In order to get the second part, run the second request.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Iterate over all items</h4>
  <CodeSnippet code={CODE.INVOCATION_EXAMPLE3} mdxType="CodeSnippet" />
  <CodeSnippet code={CODE.INVOCATION_EXAMPLE4} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <hr></hr>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`Step`}</h2>
        <h4>{`Required parameters`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Attribute`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`periodStart`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`datetime`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The date and time in UTC, RFC3339 format (Y-m-d\\TH:i:sP) for the start of the reporting period.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`periodEnd`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`datetime`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The date and time in UTC, RFC3339 format (Y-m-d\\TH:i:sP) for the end of the reporting period. This must be after periodStart.`}</td>
            </tr>
          </tbody>
        </table>
        <h4>{`Optional parameters`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Attribute`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`periodGroup`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The time granularity to group the statistics. Valid values are `}<inlineCode parentName="td">{`none, hour, day, week, month, year`}</inlineCode>{`. `}<strong parentName="td">{`Default: `}<inlineCode parentName="strong">{`day`}</inlineCode>{`.`}</strong></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`timezone`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The timezone to group and display the results in the time-dimension, such as “Europe/Amsterdam” or “US/Central”. All location names in the IANA Time Zone Database are valid. `}<strong parentName="td">{`Default: `}<inlineCode parentName="strong">{`UTC`}</inlineCode>{`.`}</strong></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`groupBy`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string list`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Zero or more values from `}<inlineCode parentName="td">{`status, flowId, action`}</inlineCode>{`. More than one value can be specified by repeating the query parameter. For example, `}<inlineCode parentName="td">{`groupBy=status&groupBy=flowId`}</inlineCode>{` groups items by status AND flowId. `}<strong parentName="td">{`Default: empty.`}</strong></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`filterBy[]`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`You can specify multiple filters as explained below. Specifying the same type of filter multiple times with different values is considered a union query, which means that the reports contains all steps that match one of the conditions. Specifying multiple different filters results in an intersection query, for example `}<inlineCode parentName="td">{`filterBy[status]=failed&filterBy[flowId]=123e4567e89b12d3a456426655440000`}</inlineCode>{` only returns step which failed and have a flowId of 123e4567e89b12d3a456426655440000. `}<strong parentName="td">{`Default: empty.`}</strong></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`filterBy[flowId]`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The UUID of the flow. It remains unchanged during its lifecycle. `}<strong parentName="td">{`Default: empty.`}</strong></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`filterBy[status]`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Status of an step. Valid values are `}<inlineCode parentName="td">{`finished`}</inlineCode>{` and `}<inlineCode parentName="td">{`failed`}</inlineCode>{`, case insensitive.  `}<strong parentName="td">{`Default: empty.`}</strong></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`filterBy[action]`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The action of the step; the thing it does. `}<strong parentName="td">{`Default: empty.`}</strong></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`sort`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Specify sort criteria with the following syntax: `}<inlineCode parentName="td">{`key1:direction1[,key2:direction2]`}</inlineCode>{`. If a direction is omitted, asc (ascending) is assumed. Valid keys are `}<inlineCode parentName="td">{`count, totalSuccessCount, totalFailureCount, successRate, failureRate`}</inlineCode>{`. Valid directions are `}<inlineCode parentName="td">{`asc, desc`}</inlineCode>{`. The appearance order of more than one key will be preserved when sorting a result. Further, the sorted result will be sorted by time in ascending order. For example, `}<inlineCode parentName="td">{`sort=count:desc`}</inlineCode>{` will sort results by count in descending order. And the items having the same sorting key values will be sorted in time ascending order if `}<inlineCode parentName="td">{`periodGroup`}</inlineCode>{` is not none. `}<strong parentName="td">{`Default: empty.`}</strong></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`offset`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`integer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The number of elements to skip. `}<strong parentName="td">{`Default: 0, skipping zero element.`}</strong></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`limit`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`integer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The number of elements to return. Valid values are any integer number between 1 and 2500, both inclusive. A number larger than 2500 will be clipped to 2500. `}<strong parentName="td">{`Default: 500.`}</strong></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`ref`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A string to ensure consistency of pagination. When accessing the first part of result, it will be included in the response. To access further parts it needs to be added as a request query parameter.`}</td>
            </tr>
          </tbody>
        </table>
        <p>{`The desired output format can be specified with the Accept header. Valid values are `}<inlineCode parentName="p">{`application/json`}</inlineCode>{` (default) and `}<inlineCode parentName="p">{`text/csv`}</inlineCode>{`.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Available HTTP Methods</h4>
  <CodeSnippet code={CODE.STEPS_METHODS} mdxType="CodeSnippet" />
  <h4>Example Request</h4>
  <CodeSnippet code={CODE.STEPS_EXAMPLE} mdxType="CodeSnippet" />  
  <h4>Example Response</h4>
  <CodeSnippet code={CODE.STEPS_RESPONSE} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <h4>{`The action table`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Trigger`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`subscribe_to_group`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An action to subscribe to a group`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`unsubscribe_from_group`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An action to unsubscribe from a group`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`send_sms`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An action to send an sms`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`forward_to_email`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An action to forward to an email`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`send_email`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An action to send an email`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`do_http_callback`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An action to do an http callback`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`transfer`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An action to do transfer`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`say`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An action to say something`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`play`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An action to play something`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`pause`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An action to pause something`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`record`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An action to record something`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`fetch_call_flow`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An action to fetch a call flow`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`hangup`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An action to hangup`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`switch`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An action to switch`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`do_outbound_voice_call`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An action to do an outbound voice call`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`await`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An action to await`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`delete_contact`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An action to delete a contact`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`jump_to_step`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An action to jump to a step`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`send_conversation_message`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An action to send a conversation message`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`The status table`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Status`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`finished`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A step has finished.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`failed`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A step has failed.`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`The step Object`}</h4>
    <p>{`A step describes a flow being invoked by a user.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`status`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Status of an step.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`flowId`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Id of the flow the step refers.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`action`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The action of the step; the thing it does.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Step Report object`}</h3>
    <p>{`This object represents a statistic.`}</p>
    <h4>{`Attributes`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`items`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`array`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Steps are split by provided grouping fields. Each item corresponds to statistics of a group.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`items[].timestamp`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`datetime`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The timestamp in RFC3339 format (Y-m-d\\TH:i:sP). The first second of hour / day / week / month / year will be used to represent the period group. For example, `}<inlineCode parentName="td">{`2018-08-06T00:00:00Z`}</inlineCode>{` represents the 32th week in 2018 or the first hour of the day August 6, 2018 or the day itself.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`items[].step`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`object`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contains details about the steps based on the groupBy parameters. If no groupBy parameter was specified this object will be empty.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`items[].successCount`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The number of successful steps in a group.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`items[].failureCount`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The number of failed steps in a group.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`items[].successRate`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`float`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The average rate of successful steps in a group.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`items[].failureRate`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`float`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The average rate of failed steps in a group.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`items[].count`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The number of items in a group.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`totalCount`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The total number of items that can be retrieved for this request through pagination.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ref`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A string to ensure consistency of pagination. A ref expires one day after creation and its lifetime cannot be extended.`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`CSV format response fields`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`timestamp`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`datetime`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The date and time in RFC3339 format (Y-m-d\\TH:i:sP). The first second of hour / day / week / month / year will be used to represent the period group. For example, `}<inlineCode parentName="td">{`2018-04-09T00:00:00Z`}</inlineCode>{` represents the 15th week in 2018 or the first hour of the day April 9, 2018 or the day itself. Exists only when periodGroup is not none.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`status`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Status of an step. Exist only when group by status`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`flowId`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Id of the flow the step refers. Exist only when group by flowId`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`action`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The action of the step; the thing it does. Exist only when group by action`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`count`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The total number of items in a group.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`successCount`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The number of successful steps in a group.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`failureCount`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The number of failed steps in a group.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`successRate`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`float`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The average rate of successful steps in a group.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`failureRate`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`float`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The average rate of failed steps in a group.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Step Example requests`}</h3>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h4>{`Steps grouped by trigger type`}</h4>
        <p>{`Group all steps made in August, 2018 UTC+8 by action to see one month steps for the trigger types.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Steps grouped by trigger type</h4>
  <CodeSnippet code={CODE.STEPS_EXAMPLE1} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h4>{`Daily steps grouped by trigger type`}</h4>
        <p>{`Group all steps made in August, 2018 UTC+8 by action and day to see daily steps for the trigger types.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Daily steps grouped by trigger type</h4>
  <CodeSnippet code={CODE.STEPS_EXAMPLE2} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h4>{`Iterate over all items`}</h4>
        <p>{`Requesting the first part gives us the first 100 items and `}<inlineCode parentName="p">{`ref: 03aa7194b10111e88981529269fb1459`}</inlineCode>{`. In order to get the second part, run the second request.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Iterate over all items</h4>
  <CodeSnippet code={CODE.STEPS_EXAMPLE3} mdxType="CodeSnippet" />
  <CodeSnippet code={CODE.STEPS_EXAMPLE4} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <hr></hr>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`Email`}</h2>
        <h4>{`Required parameters`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Attribute`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`periodStart`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`datetime`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The date and time in UTC, RFC3339 format (Y-m-d\\TH:i:sP) for the start of the reporting period.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`periodEnd`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`datetime`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The date and time in UTC, RFC3339 format (Y-m-d\\TH:i:sP) for the end of the reporting period. This must be after periodStart.`}</td>
            </tr>
          </tbody>
        </table>
        <h4>{`Optional parameters`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Attribute`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`periodGroup`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The time granularity to group the statistics. Valid values are `}<inlineCode parentName="td">{`none, hour, day, week, month, year`}</inlineCode>{`. `}<strong parentName="td">{`Default: `}<inlineCode parentName="strong">{`day`}</inlineCode>{`.`}</strong></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`timezone`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The timezone to group and display the results in the time-dimension, such as “Europe/Amsterdam” or “US/Central”. All location names in the IANA Time Zone Database are valid. `}<strong parentName="td">{`Default: `}<inlineCode parentName="strong">{`UTC`}</inlineCode>{`.`}</strong></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`groupBy`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string list`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Zero or more values from `}<inlineCode parentName="td">{`channelId, status`}</inlineCode>{`. More than one value can be specified by repeating the query parameter. For example, `}<inlineCode parentName="td">{`groupBy=channelId&groupBy=status`}</inlineCode>{` groups items by channel AND status. `}<strong parentName="td">{`Default: empty.`}</strong></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`filterBy[]`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`You can specify multiple filters as explained below. Specifying the same type of filter multiple times with different values is considered a union query, which means that the reports contains all emails that match one of the conditions. Specifying multiple different filters results in an intersection query, for example `}<inlineCode parentName="td">{`filterBy[channelId]=b54ccc2b5684473aa6f6863309e61fdb&filterBy[status]=received`}</inlineCode>{` returns statistics over emails received by recipients through channel b54ccc2b5684473aa6f6863309e61fdb.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`filterBy[channelId]`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Filters by channel id, for example `}<inlineCode parentName="td">{`filterBy[channelId]=ad27e9240ba241a792ce54dfd330f360&filterBy[channelId]=0fc77eed542d4397b8eac6725b626472`}</inlineCode></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`filterBy[messageId]`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A UUID identifying an email. For example `}<inlineCode parentName="td">{`filterBy[messageId]=b54ccc2b5684473aa6f6863309e61fdb&filterBy[messageId]=fc1f5798491e481b93f13452e3f55407`}</inlineCode>{`. `}<strong parentName="td">{`Default: empty.`}</strong></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`filterBy[status]`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Filters by email status, valid values are `}<inlineCode parentName="td">{`accepted, delayed, failed, opened, received, sent`}</inlineCode>{`. Note a status is transitional. An email could go through `}<inlineCode parentName="td">{`accepted`}</inlineCode>{` to `}<inlineCode parentName="td">{`sent`}</inlineCode>{` to `}<inlineCode parentName="td">{`received`}</inlineCode>{` and `}<inlineCode parentName="td">{`opened`}</inlineCode>{` but it can be in only one status at one moment. For example `}<inlineCode parentName="td">{`filterBy[status]=accepted&filterBy[status]=received`}</inlineCode></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`sort`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Specify sort criteria with the following syntax: `}<inlineCode parentName="td">{`key1:direction1[,key2:direction2]`}</inlineCode>{`. Valid keys are `}<inlineCode parentName="td">{`count`}</inlineCode>{`. Valid directions are `}<inlineCode parentName="td">{`asc, desc`}</inlineCode>{`. If a direction is omitted, asc is assumed. The appearance order of more than one key will be preserved when sorting a result. Further, the sorted result will be sorted by time in ascending order. For example, `}<inlineCode parentName="td">{`sort=count:desc`}</inlineCode>{` will sort results by `}<inlineCode parentName="td">{`count`}</inlineCode>{` in descending order. And the items having the same count will be sorted in time ascending order.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`offset`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`integer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The number of elements to skip. `}<strong parentName="td">{`Default: 0, skipping zero element.`}</strong></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`limit`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`integer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The number of elements to return. Valid values are any integer number between 1 and 2500, both inclusive. A number larger than 2500 will be clipped to 2500. `}<strong parentName="td">{`Default: 500.`}</strong></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`ref`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A string to ensure consistency of pagination. When accessing the first part of result, it will be included in the response. To access further parts it needs to be added as a request query parameter.`}</td>
            </tr>
          </tbody>
        </table>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Available HTTP Methods</h4>
  <CodeSnippet code={CODE.EMAIL_METHODS} mdxType="CodeSnippet" />
  <h4>Example Request</h4>
  <CodeSnippet code={CODE.EMAIL_EXAMPLE} mdxType="CodeSnippet" />  
  <h4>Example Response</h4>
  <CodeSnippet code={CODE.EMAIL_RESPONSE} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <p>{`The desired output format can be specified with the Accept header. Valid values are `}<inlineCode parentName="p">{`application/json`}</inlineCode>{` (default) and `}<inlineCode parentName="p">{`text/csv`}</inlineCode>{`.`}</p>
    <h3>{`Email Report object`}</h3>
    <p>{`This object represents a statistic.`}</p>
    <h4>{`Attributes`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`items`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`array`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Emails are split by provided grouping fields. Each item corresponds to statistics of a group.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`items[].timestamp`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`datetime`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The timestamp in RFC3339 format (Y-m-d\\TH:i:sP). The first second of hour / day / week / month / year will be used to represent the period group. For example, `}<inlineCode parentName="td">{`2019-03-27T08:00:00+01:00`}</inlineCode>{` represents the start of 8AM March 27, 2019 in UTC+1.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`items[].email`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`object`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contains details about the messages based on the groupBy parameters. If no groupBy parameter was specified this object will be empty.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`items[].count`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The number of emails submitted to MessageBird in the specified time period. If email status is not empty, it represents the number of emails in that status when a request is being made.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ref`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A string to ensure consistency of pagination. A ref expires one day after creation and its lifetime cannot be extended.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`totalCount`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The total number of items that can be retrieved for this request through pagination. Note this is not the sum over all `}<inlineCode parentName="td">{`count`}</inlineCode>{` in each item.`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`The email Object`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`channelId`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Channel id, a UUID string. Exists only when group by channelId.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`status`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Email status. Exists only when group by status.`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`CSV format response fields`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`timestamp`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`datetime`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The date and time in RFC3339 format (Y-m-d\\TH:i:sP). The first second of hour / day / week / month / year will be used to represent the period group. For example, `}<inlineCode parentName="td">{`2018-04-09T00:00:00Z`}</inlineCode>{` represents the 15th week in 2018 or the first hour of the day April 9, 2018 or the day itself. Exists only when periodGroup is not none.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`channelId`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Channel id, a UUID string. Exists only when group by channelId.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`status`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Email status. Exists only when group by status.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`count`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The number of emails.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Email Example requests`}</h3>
    <p>{`You can retrieve a variety of insights from the Reporting API through different combinations of filterBy and groupBy parameters. We have collected a variety of requests below to serve as an inspiration for you to build your own queries.`}</p>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h4>{`Number of emails accepted by MessageBird in June, 2019.`}</h4>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Submitted emails in June</h4>
  <CodeSnippet code={CODE.EMAIL_EXAMPLE_JUNE} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h4>{`Number of emails accepted by MessageBird per month in 2019.`}</h4>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Submitted emails grouped by month</h4>
  <CodeSnippet code={CODE.EMAIL_EXAMPLE_MONTHLY} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h4>{`Number of emails accepted by MessageBird per status in June, 2019.`}</h4>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Submitted emails grouped by status</h4>
  <CodeSnippet code={CODE.EMAIL_EXAMPLE_STATUS} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h4>{`Number of emails received per day in June, 2019.`}</h4>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Received emails per day in June</h4>
  <CodeSnippet code={CODE.EMAIL_EXAMPLE_FILTER} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h4>{`Number of emails accepted by MessageBird per day in March, 2019, Amsterdam time.`}</h4>
        <p>{`Note periodStart is in UTC+1 while periodEnd is in UTC+2 and timezone is set to Europe/Amsterdam due to daylight saving time.
In response, the timestamp also carries timezone information, for example `}<inlineCode parentName="p">{`2019-03-27T00:00:00+01:00`}</inlineCode>{`.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Submitted emails per day in Amsterdam time</h4>
  <CodeSnippet code={CODE.EMAIL_EXAMPLE_AMSTERDAM} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h4>{`A large number of items (`}<inlineCode parentName="h4">{`totalCount`}</inlineCode>{` is large).`}</h4>
        <p>{`First request, `}<inlineCode parentName="p">{`offset=0&limit=100`}</inlineCode>{`. The second request `}<inlineCode parentName="p">{`offset=0&limit=100&ref=0226f90e567d43c6ae160fe0ddfa5560`}</inlineCode>{`.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>A large number of items</h4>
  <CodeSnippet code={CODE.EMAIL_EXAMPLE_PART1} mdxType="CodeSnippet" />
  <CodeSnippet code={CODE.EMAIL_EXAMPLE_PART2} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      